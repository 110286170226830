#header{
    /* display: grid; */
    grid-area: header;
    display: flex;
    flex-direction: row;
}

.active-sensors{
    display: flex;
    flex-wrap: nowrap;
    margin-left: 1rem;
}

.active-sensor-tag{
    display: flex;
    align-items: center;
    height: 24px;
    width: 24px;
    background-color: #00502c;
    color: #fff;
    font-weight: 700;
    text-align: center;
    border-radius: 50%;
    margin-right: 8px;
}

.active-sensor-tag p{
    margin-left: 6px;
}

.brand{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: .5rem .5rem;
}

.brand-icon{
    /* margin: .7rem 1rem 0 0; */
    width:24px;
    margin-right:1rem;
}

.brand-name{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom:0;
}

.brand-logo{
    align-items: center;
    margin-bottom:0;
}

.brand-tag{
    margin-top:0;
    font-size: .9rem;
    color: #767676;
    font-weight: 400;
}

.header{
    display: flex;
    flex-direction: row;
    background-color: #fff;
    padding: 0 2rem 0 2rem;
    border-bottom: 4px solid #00502c;
    align-items: center;
    gap: 1rem;
}

.header h1{
    color: #00502c;
    font-size: 1.3rem;
    display: inline-block;
    
}


.header input{
    padding: 12px 1rem;
    box-sizing: border-box;
    border: 2px solid #ccc;
    display: inline-block;
}

.header-links-container{
    display: flex;
    width:100%;
    justify-content: end;
}

ul.header-links{
    list-style-type: none;
    display: flex;
    margin-left: -2rem;
    flex-direction: row;
    gap: 1rem;
}

.header-links li a {
    text-decoration: none;
    color:#4c4c4c;
}

.header-links li a:hover {
    text-decoration: none;
    color:#4c4c4c;
    font-weight:600;
}
