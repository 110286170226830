.loader-container {
    display: flex;
    height: 100vh;
    /* position: fixed;
    z-index: 1; */
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.spinner {
    /* width: 64px;
    height: 64px; */
    /* border: 8px solid; */
    /* border-color: #5F01F5 transparent #5F01F5 transparent; */
    /* border-radius: 50%; */
    animation: fadeIn 1.2s linear infinite;
    /* margin: 0 auto; */
}

@keyframes spin-anim {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes fadeIn {  
    from {  
        opacity:0;  
    }  
 
    to {  
        opacity:1;  
    }  
 }