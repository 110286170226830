.space-button {
    width: 200px;
    border-radius: 4px;
    padding: .5rem .5rem;
    background-color: transparent;
    border: 0px;
    text-align: left;
}

.space-button:hover {
    background-color: #e5e5e5;
}

#space-text {
    font-size: 19px;
}

#selected {
    font-weight: 700;
    color:#2e2e2e;
}

