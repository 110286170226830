body {
    background-color: #fff;
    color: #2e2e2e;
}

p {
    font-size: 1.1rem;
    line-height: 1.7rem;
}

.scroll-button{
    display: flex;
    justify-content: center;
    margin-top: 10rem;
    bottom: 20px;
    transform: translate(-50%, -50%);
    width: 64px;
    height: 64px;
    border-radius: 100%;
    /* border: 2px solid #00502c; */
    background-color:transparent;

    color: #00502c;
}

.scroll-button:hover{
    background-color: #00311b;
    color: #fff;
}

.scroll-icon{
    display: flex;
    align-items: center;
}

.icon{

    width:64px;
}

.icon img{
    width: 48px;
    align-items: center;
}

.berk-logo{
    width: 200px;
}

.landing-container{
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    height: 100vh;

}



.landing-container h1{
    margin: 0;
    color: #2e2e2e;
    font-size: 5rem;
    letter-spacing: -.1rem;
}

.landing-container h4{
    margin: 0;
    font-size: 2.4rem;
    letter-spacing: 0;
    color: #4c4c4c;
    margin-bottom: 3rem;
    font-weight: 500;
}

.landing-container p{
    font-size: 1.2rem;

}

.hero-left{
    width: 50%;
}

.hero-right{
    width: 50%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.vertical-half{
    height:50%;
}

.hero-inner{
    padding: 5rem;

}

.landing-img{
    margin: 0 auto;
    width: 100%;
}

.description{
    width: 75%;
    margin-top: 3rem;
}

.description > p{
    font-weight: 300;
    color: #4c4c4c;
}

.btn-large {
    padding: 1.5rem 2rem 1.5rem 2rem;
    font-size: 1.1rem;
    display: inline-flex;
    
}

.btn-text{
    margin-right: 1rem ;
}

/* Project Overview */
.project-overview{
    background-color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 4rem 12rem 4rem 12rem;
    /* margin-top: -2rem; */
}

.overview-inner{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 75%;
    border-radius: 16px;
    padding: 2rem 4rem;
}

.overview-hl{
    font-weight: 700;
    color: #00502c;
}

.overview-right{
    width:50%;
}

.overview-header{
    display: flex;
}

.overview-header h2{
    font-size: 2rem;
    font-weight: 400;
    line-height: 2.8rem;
}

.overview-body{
    
    display: flex;
    flex-direction: row;
}

.overview-body p{
    font-size: 1.3rem;
    color: #2e2e2e;
}

/* Dashboard */

#sec-dashboard{
    display: flex;
    flex-direction: row;
}

.dashboard-header {
    padding: 4rem 4rem 4rem 4rem;
}

.dashboard-header > h2 {
    color:#00502c;
    font-size: 2rem;
}

.dashboard-header > p{
    font-size: 1.2rem;
}

.half-left{
    overflow: hidden;
    width: 50%;
}
.half-right{
    overflow: hidden;
    width: 50%;

}


/* Team */

#sec-project-team{
    background-color: #00311b;
    padding: 2rem;
}



.team-container{
    display: flex;
    flex-direction: row;
    padding: 2rem;
}

.team-header > h2{
    padding-top: 2rem;
    text-align: center;
    color:#fff;
    font-size: 2rem;
}

.team-header > p{
    text-align: center;
    font-size: 1.2rem;
    color: #fff;
}

.team-member-card{
    margin: 3rem;
    background-color: #fff;
}

.card-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    /* margin: 1rem; */
    padding: 1rem;
}

.card-content > p{
    font-size: .9rem;
    font-weight: 700;
    line-height: 1rem;
}

.card-content > h2 {
    font-weight: 500;
}

.team-member-card> img{
    width: 100%;
}

.team-member-card p{
    text-align: center;
}

