.dashboard-container{
    display: flex;
    align-content: center;
    justify-content: center;
    margin-top: 12rem;
}

.container > h2{
    margin-top: 0;
}

.dash-container-inner{
    display: flex;
    flex-direction: column;
    align-items: center;
}