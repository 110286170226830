#sidebar-container{
    display: flex;
    grid-area: sidebar;
    /* width: 100%; */
    height: 100vh;
    background-color: #fdfdfd;
    color: #2e2e2e;
    border-right: 2px solid #003226;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}


a {
    color: #2e2e2e;
    text-decoration: none;
    vertical-align: center;
}

i {
    display: flex;
    align-items: center;
    margin-right:1em;
}

a:visited {
    text-decoration: none;
}

a:active {
    text-decoration: none;
}

a:hover {
    text-decoration: none;
    font-weight: 600;
}
 

.sidebar {
    padding: 1rem 1rem 1rem 1rem;
}

h2 {
    font-size: 1rem;
}

.sidebar ul{
    width: 100%;
    display: flex;
    flex-direction: column;
    list-style: none;
    color: #00502c;
}

.sidebar li {
    width:100%;
    display: flex;
    flex-direction: row;
    color: #2e2e2e;
    margin: 1.2rem .5rem;
    text-decoration: none;
}

.add-button{
    width:100%;
    display: flex;
    flex-direction: row;
}

.nav-item{
    display: flex;
    flex-direction: row;
    align-items: center;
} 

.btn-add{
    display: flex;
    flex-direction: row;
    width:100%;
    background-color: #00502c;
    color: #fff;
}

.btn-add:hover{
    display: flex;
    flex-direction: row;
    width:100%;
    background-color: #003226;
    color: #fff;
}

.country-space {
    margin-top: 10px;
}