.sensor-grid{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    /* column-gap: 3rem; */
    /* row-gap: 3rem; */
    gap: 3rem;
}

.sensor-card{
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border-radius:4px;
}

.sensor-card-inner {
    display:flex;
    flex-direction: column;
    padding: 2rem;
    text-transform: capitalize;
}

.sensor-card-inner .btn{
    width:100%;
}

.placeholder{
    display: flex;
    align-items: center;
    margin: 0 auto;
    background-color: #ecfcf7;
    width:100%;
    height: 160px;
}

.place-ic{
    margin: 0 auto;
}