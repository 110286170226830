.job-card p {
    margin-top: 4px;
    font-size:.9rem;
}

.job-card{
    background: #fff;
    padding: 1.5rem 1.5rem;
    color: #2e2e2e;
    /* border: 1px solid #ccc; */
    border-radius: 1rem;
    position: relative;
    transition: transfrom 0.2s;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}

.job-card:hover {
    cursor: pointer;
    transform: scale(1.02);
}

.description-container{
    margin: 0 0 1rem 0;

}

.btn-group{
    display:flex;
}


.btn {
    margin-right: 1rem;
    border-radius: 4px;
    border: none;
    padding: 1rem 1.5rem 1rem 1.5rem;
    font-size:.9rem;
}

a.btn {
    text-decoration: none;
    font-weight: 600;
}

.btn-primary{
    background-color: #00502c;
    color: #fff;
}

.btn-primary:hover{
    background-color: #003226;
    color: #fff;
}

.btn-tertiary{
    background-color: #fff;
    color: #5F01F5;
}

.btn-tertiary:hover{
    background-color: rgb(224, 224, 224);
    color: #5F01F5;
}


.card-title{
    margin-top:0;
    font-weight: 400;
    font-size: 1.3rem;
}

.card-heading{
    font-size: .8rem;
    margin-bottom:0;
    color: #767676;
    font-weight: 400;
    letter-spacing: .5px;
}